<template>
  <svg 
    id="logoOrisisFull" 
    data-name="Logo_ORISIS" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 263.39 64.56"
    style="cursor: pointer !important;"
  >
    <title>Logo ORISIS</title>
    <path class="cls-1" d="M31.73,25.11a7.08,7.08,0,1,1-7.09,7.08A7.08,7.08,0,0,1,31.73,25.11Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M31.93,63.6a3.42,3.42,0,0,1-2.43-1,3.35,3.35,0,0,1-1-2.39,3.41,3.41,0,0,1,3.36-3.45,24.7,24.7,0,0,0,24.45-25A24.83,24.83,0,0,0,31.65,7.39h-.19A24.74,24.74,0,0,0,7,32.38a24,24,0,0,0,.4,4.14c0,.09,0,.15,0,.2a3.4,3.4,0,0,1-3.34,4H3.9A3.41,3.41,0,0,1,.56,38,31.53,31.53,0,0,1,25.66,1.17,32.31,32.31,0,0,1,31.61.61h.18a31.5,31.5,0,0,1,.32,63h-.18Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M31.35,51.24a18.9,18.9,0,0,1-8.66-35.7,3.23,3.23,0,0,1,1.45-.35A3.19,3.19,0,0,1,27,16.91a3.13,3.13,0,0,1,.2,2.42,3.1,3.1,0,0,1-1.57,1.85,12.57,12.57,0,0,0-3.06,20.11A12.58,12.58,0,0,0,43.87,30.91a3.18,3.18,0,0,1,2.8-3.51l.37,0a3.15,3.15,0,0,1,2,.69,3.21,3.21,0,0,1,1.18,2.13A19,19,0,0,1,33.51,51.12,19.68,19.68,0,0,1,31.35,51.24Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M168,64.21c-8,0-12.83-.46-20.51-3.19a3.8,3.8,0,0,1-2.15-1.94,3.79,3.79,0,0,1,3.42-5.4,3.94,3.94,0,0,1,1.27.22c6.57,2.33,10.42,2.75,18,2.75,5.39,0,9.49-1,12.2-3s4.15-5.07,4.16-9.08c0-3.42-1-6-3.08-7.77s-5.09-2.58-9.09-2.58h-8c-6.33,0-11.13-1.38-14.25-4.1s-4.69-6.84-4.7-12.28c0-5.72,2-10.07,6-12.91S161.47.62,169.53.62h.79a60.34,60.34,0,0,1,17.4,2.57,3.78,3.78,0,1,1-1.61,7.38l-.4-.11a52.59,52.59,0,0,0-15.41-2.3h-.61c-8.14,0-12.09,1.59-14,2.93-1.4,1-2.84,2.59-2.84,6.76,0,4.76,1.57,6.13,2.09,6.58,1.67,1.45,5,2.24,9.29,2.24h8c5.83,0,10.53,1.47,14,4.37,2.63,2.23,5.78,6.39,5.78,13.54,0,8.16-4,12.76-7.27,15.18C180.57,62.75,175.11,64.21,168,64.21Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M117.84,64.46a3.77,3.77,0,0,1-3.25-1.84L96.39,36.48a3.75,3.75,0,0,1-.52-1,3.79,3.79,0,0,1,.17-2.9,3.74,3.74,0,0,1,2.17-1.92c4.56-1.53,8.12-3.53,10.29-5.77a9.58,9.58,0,0,0,2.89-7c0-3.51-.83-5.61-2.68-7.25s-5.2-2.5-9.67-2.5H79.13a.43.43,0,0,0-.43.43V60.88a3.77,3.77,0,0,1-3.6,3.57h-.19a3.79,3.79,0,0,1-3.79-3.6V4.4A3.79,3.79,0,0,1,74.91.61H99c6.32,0,11.08,1.42,14.54,4.28S119,12.4,119,17.94a17.29,17.29,0,0,1-5,12.21,31.81,31.81,0,0,1-8.15,5.61.41.41,0,0,0-.23.28.42.42,0,0,0,.07.36l15,21.74a3.94,3.94,0,0,1,.42.57,3.76,3.76,0,0,1,.44,2.87,3.8,3.8,0,0,1-3.68,2.88Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M133.54.69a3.77,3.77,0,0,0-3.78,3.6,2.41,2.41,0,0,0,0,.38L129.51,61a3.78,3.78,0,0,0,3.79,3.58h.19a3.79,3.79,0,0,0,3.6-3.6l.24-56.28a3.79,3.79,0,0,0-3.6-4Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M239.45,64.22c-8,0-12.83-.47-20.51-3.19a3.79,3.79,0,0,1-2.29-4.83,3.79,3.79,0,0,1,3.56-2.51,3.69,3.69,0,0,1,1.27.22c6.58,2.33,10.43,2.74,18,2.74,5.39,0,9.49-1,12.2-3s4.15-5.07,4.17-9.08c0-3.42-1-6-3.09-7.77s-5.06-2.58-9-2.58h-8c-6.34,0-11.14-1.38-14.26-4.1s-4.69-6.84-4.7-12.29c0-5.72,2-10.06,6-12.91S232.93.61,241,.61h.71a60.13,60.13,0,0,1,17.42,2.57,3.78,3.78,0,0,1-1.23,7.35,3.81,3.81,0,0,1-.82-.09H257a52.83,52.83,0,0,0-15.34-2.28h-.54c-8.15,0-12.1,1.6-14,2.93-1.41,1-2.85,2.6-2.85,6.76,0,4.76,1.57,6.13,2.09,6.58,1.67,1.45,5,2.25,9.3,2.25h7.94c5.84,0,10.54,1.47,14,4.36,2.64,2.23,5.78,6.39,5.78,13.55,0,8.16-3.95,12.76-7.26,15.18C252,62.76,246.55,64.22,239.45,64.22Z" transform="translate(0 -0.61)"/>
    <path class="cls-2" d="M205,.69a3.78,3.78,0,0,0-3.78,3.6,2.28,2.28,0,0,0,0,.37L201,61a3.77,3.77,0,0,0,3.78,3.58h.19a3.79,3.79,0,0,0,3.6-3.6l.24-56.28a3.79,3.79,0,0,0-3.6-4Z" transform="translate(0 -0.61)"/>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      color: null,
    };
  },
};
</script>

<style scoped>
#logoOrisisFull{
  fill:#0c3571;
  fill-rule:evenodd;
}
</style>